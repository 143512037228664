<template>
  <v-container class="mt-0 pt-0">
    <elConfigList>
      <elConfigListSwitchItem title="Modo Escuro" v-model="config.modoEscuro" />
      <elConfigListSwitchItem
        title="Botão de ação redondo"
        v-model="config.botaoRedondo"
      />
    </elConfigList>
    <elAbout />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { SET_CONFIG, GET_MODOESCURO, GET_BOTAOREDONDO } from '@/store/config.js'

import { elAbout, elConfigList, elConfigListSwitchItem } from '@/components'

export default {
  name: 'ConfiguracaoView',
  components: { elAbout, elConfigList, elConfigListSwitchItem },
  data() {
    return {
      config: {
        modoEscuro: false,
        botaoRedondo: false
      }
    }
  },
  watch: {
    config: {
      handler(value) {
        this[SET_CONFIG](value)
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters('config', [GET_MODOESCURO, GET_BOTAOREDONDO])
  },
  methods: {
    ...mapActions('config', [SET_CONFIG])
  },
  mounted() {
    this.config.modoEscuro = this[GET_MODOESCURO]
    this.config.botaoRedondo = this[GET_BOTAOREDONDO]
  }
}
</script>
